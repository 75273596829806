@font-face {
  src: url('./assets/fonts/AWConquerorSans-Light.otf');
  font-family: 'AW-Text';
}

@font-face {
  src: url('./assets/fonts/AWConquerorSlab-Light.otf');
  font-family: 'AW-Titulo';
}

:root {
  --main-bg-color: #f1f2f2;
  --main-txt-color: black;
  --app-bg: black;
  --menu-bg-color: #fff;
  --menu-bg-over: #fbb040;
  --menu-txt-color: #000;
}

.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 40s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'AW-Titulo';
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/*  NEW CSS */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'AW-Text';
  background-color: var(--main-bg-color);
  color: var(--main-txt-color);
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

header {
  display: flex;
  justify-content: center;
  box-shadow: 0 1px 3px 0 rgba(254, 252, 252, 0.07), 0 1px 2px 0 rgba(255, 255, 255, 0.05);
  /*color: #212529;*/
  color: var(--main-txt-color);
  background-color: var(--main-bg-color);
  height: 100px;
  font-family: 'AW-Titulo';
}


/* FOOTER DEWSKTOP */
footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /*color: #212529;*/
  color: var(--main-txt-color);
  background-color: #000;
  font-family: 'AW-Titulo';
  padding: 0px 0px 0px 0px;
}

.footerLeft {
  flex: 0.67;
  height: 300px;
  flex-direction: column;
  background-color: orange;
}

.footerRight {
  flex: 0.33;
  height: 350px;
  background-color: black;
  padding-left: 3em;
  padding-top: 2em;
}

.footerLUp {
  height: 200px;
  background-color: black;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  padding-left: 3em;
  padding-top: 1em;
}

.footerImg {
  flex: 1;
  background-color: black;
  align-items: center;
  display: flex;
  justify-content: center;
}

.footerLDown {
  display: flex;
  height: 100px;
  background-color: black;
  align-items: left;
  justify-content: center;
}

.footer-a {
  color: var(--main-bg-color);
  font-size: 1em;
  text-decoration: none; 
}

.footer-a-extern {
  color: var(--main-bg-color);
  target: new;
  target-new: tab;
}

.footer-img-barra {
  height: 80px;
}

.footer-img-rota {
  height: 40px;
  margin-left: 4em;
}

.footer-img-logo {
  height: 100px;
}

.footer-img-cijlp {
  height: 200px ;
  margin-left: 2em;
}

.footer-img-insta {
  height: 20px;
  margin-right: 1em;
}

.footerp {
  font-family: 'AW-Text';
  color: white;
  font-size: 1em;
}

/* FOOTER MOBILE */

.footer-mobile {
  position: relative;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  /*color: #212529;*/
  color: var(--main-txt-color);
  background-color: #222;
  font-family: 'AW-Titulo';
  padding: 0px 0px 0px 0px;
}

.footerLeft-mobile {
  flex: 0.67;
  height: 300px;
  flex-direction: column;
  background-color: orange;
}

.footerRight-mobile {
  flex: 0.33;
  height: 300px;
  background-color: black;
  padding-left: 2em;
  padding-top: 1em;
}

.footerLUp-mobile {
  height: 200px;
  background-color: black;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  padding-left: 1em;
  padding-top: 1em;
}

.footerImg-mobile {
  flex: 1;
  background-color: black;
  align-items: center;
  display: flex;
  justify-content: center;
}

.footerLDown-mobile {
  display: flex;
  height: 100px;
  background-color: black;
  align-items: center;
  justify-content: center;
}

.footer-a-mobile {
  color: var(--main-bg-color);
  font-size: 1em;
  text-decoration: none; 
}

.footer-a-extern-mobile {
  color: var(--main-bg-color);
  target: new;
  target-new: tab;
}

.footer-img-barra-mobile {
  height: 60px;
}

.footer-img-rota-mobile {
  height: 30px;
  margin-left: 2em;
}

.footer-img-logo-mobile {
  height: 80px;
}

.footer-img-cijlp-mobile {
  height: 150px ;
  margin-left: 0.5em;
}

.footer-img-insta-mobile {
  height: 20px;
  margin-right: 1em;
}


.footer-area {
  flex: 1;
  padding: 0px 0px 0px 0px;
  height:100px;
  background-color: #000;
  color: white;
}

.footerini {
  flex: 0.1;
  padding: 0px 0px 0px 0px;
  height:250px;
  background-color: #222;
  color: white;
  align-items: center;
  justify-content: center;
}

.footermiddle {
  flex: 0.2;
  padding: 10px 0px 0px 0px;
  height:250px;
  background-color: #222;
  color: white;
  flex-direction: row;
  display: flex;
}

.footerfirst {
  flex: 0.4;
  padding: 0px 0px 0px 0px;
  height:250px;
  background-color: #222;
  color: white;
  align-items: center;
  justify-content: center;
}

.footersecond{
  flex: 0.3;
  padding: 20px 0px 0px 20px;
  height:250px;
  color: white;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: #222;
  
}



.main-hr {
  border: 0.5px solid var(--main-txt-color);
}

.main-div {
  font-size: 1.2em;
  text-align: left;
  font-family: 'AW-Text';
}

.main-container {
  background-color: var(--main-bg-color);
}

.main-container-caroussel {
  width: 100%;
  
}

.nav-area { /* container div in header */
  display: flex;
  align-items: center;
  flex: 0.9;
  margin: 0 auto;
  padding: 0px 0px;
  background-color: var(--main-bg-color);
  height: 100%;
  width: 100%;
}

.logo-container {
  flex: 0.25;
  height: 100%;
  width: 100%;
  background-color: var(--main-bg-color);
  align-items: center;
  justify-content: center;
}

.menu-container {
  flex: 0.70;
  font-family: 'AW-Titulo';
}

.logo {
  display: flex;
  height: 100%;
  text-decoration: none;
  font-size: 25px;
  color: inherit;
  margin-right: 0px;
  align-items: center;
  padding-left: 20%;
}

.logo-img {
  
}

/* menu on desktop */
.desktop-nav .menus {
  display: flex;
  flex: 0.9;
  align-items:flex-end;
  flex-wrap: wrap;
  list-style: none;
}

.desktop-nav .menu-items {
  position: relative;
  font-size: 20px;
}

.desktop-nav .menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.desktop-nav .menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

.desktop-nav button span {
  margin-left: 3px;
}

.desktop-nav .menu-items > a,
.desktop-nav .menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
  font-family: 'AW-Titulo';
}

.desktop-nav .menu-items a:hover,
.desktop-nav .menu-items button:hover {
  /*background-color: #f2f2f2;*/
  background-color: var(--menu-bg-over);
}

.desktop-nav .arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.desktop-nav .dropdown {
  position: absolute;
  /* right: 0;
  left: auto; */
  right: auto;
  left: 0%;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 5em;/*0.875rem;*/
  z-index: 9999;
  width: 100%;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: var(--menu-bg-color);
  border-radius: 0 0.5rem 0.5rem 0;
  display: none;
}

.desktop-nav .dropdown.show {
  display: block;
}

.desktop-nav .dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
  background-color: var(--menu-bg-color);
}

.mobile-nav {
  display: none;
}

/* menu on mobile */
@media screen and (max-width: 960px) {
  .nav-area {
    justify-content: space-between;
    font-family: 'AW-Titulo';
  }

  .desktop-nav {
    display: none;
    text-align: right;
  }

  .mobile-nav {
    display: block;
  }
  .mobile-nav .mobile-nav__menu-button {
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    position: relative;
    font-family: 'AW-Titulo';
  }
  .mobile-nav .menus {
    list-style: none;
    position: absolute;
    top: 50px;
    right: 20px;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    z-index: 9999;
    min-width: 12rem;
    padding: 0.5rem 0;
    background-color: #fff;
    border-radius: 0.5rem;
    font-family: 'AW-Titulo';
  }

  .mobile-nav .menu-items a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
    font-family: 'AW-Titulo';
  }

  .mobile-nav .menu-items button {
    display: flex;
    align-items: center;
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
  }

  .mobile-nav .menu-items > a,
  .mobile-nav .menu-items button {
    text-align: left;
    padding: 0.7rem 1rem;
    font-family: 'AW-Titulo';
  }

  .mobile-nav .menu-items a:hover,
  .mobile-nav .menu-items button:hover {
    background-color: #f2f2f2;
  }

  .mobile-nav .arrow::after {
    content: "";
    display: inline-block;
    margin-left: 1.2em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }
  .mobile-nav .arrow-close::after {
    content: "";
    display: inline-block;
    margin-left: 1.2em;
    vertical-align: 0.09em;
    border-bottom: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }

  .mobile-nav .dropdown {
    margin-left: 1.2em;
    font-size: 0.9rem;
    padding: 0.5rem 0;
    list-style: none;
    display: none;
  }

  .mobile-nav .dropdown.show {
    display: block;
  }
}

/* page content */
.content {
  max-width: 1200px;
  min-height: calc(100vh-300px);
  margin: 0 auto;
  padding: 1rem 20px;
}

.content h1 {
  font-size: 2rem;
  word-break:break-word;
  text-align: left;
  font-family: 'AW-Titulo';
}

#error-page {
  /* center content on the page */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 20px;
}

a {
  color: #333;
  target: new;
  target-new: tab;
}

/* CBCARD */

.cbcard-container {
  background-color: #aaa;
  position: relative;
  border-radius: 0px;
  margin-bottom:  5px;
}


.cbcard-container::after {
  position: absolute;
  content: "";
  width: 30px;
  height: 30px;
  right: -2px;
  bottom: -2px;
  z-index:-1;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}

.cbcard-container::before {
  position: absolute;
  content: "";
  width: 30px;
  height: 30px;
  top: -2px;
  left: -2px;
  z-index:-1;
  border-left: 1px solid #000;
  border-top: 1px solid #000;
}

.cbcard-content {
  padding: 16px;
  display: flex;
  flex-direction: row;  
  z-index: 100;
  background-color: #ddd;
  color: var(--main-txt-color);
}

.cbcard-content-mobile {
  padding: 16px;
  display: flex;
  flex-direction: column;
  z-index: 100;
  background-color: #ddd;
  color: var(--main-txt-color);
}

.cbcard-title {
  height: '100%';
  font-family: 'AW-Titulo';
  margin-bottom: 1em;
}

.cbcard-body {
  flex: 0.8;
  flex-direction: column;
  width: 70%;
  color: var(--main-txt-color); 
}

.cbcard-body-video {
  flex: 0.6;
  flex-direction: column;
  width: 70%;
  color: var(--main-txt-color); 
}

.cbcard-desc {
  flex: 1;
  padding-right: 10px;
  color: var(--main-txt-color);
}

.cbcard-img-container {
  flex: 0.2;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  overflow-x: hidden;
}

.cbcard-img-container-video {
  flex: 0.4; 
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cbcard-img-container-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
  overflow-x: hidden;
}

.cbcard-img {
  width: 100%;
  height: auto;
  max-height: 350px;
  object-fit:contain;
}



.cbcard-datetxt {
  font-size: 1.3em;
}

/* TitleHeader */
.th-container {
  width: 100%;
  margin-bottom: 2em;
  margin-top: 2em;
  color: var(--menu-bg-over);
  font-family: 'AW-Titulo';
  text-align: left;
}

.th-text {
  font-family: 'AW-Titulo';
  font-weight: bold;
  font-size: 5em;
}

.th-text-mobile {
  font-family: 'AW-Titulo';
  font-weight: bold;
  font-size: 2em;
}

.lang-text {
  font-family: 'AW-Titulo';
  font-size: 1em;
}

.th-text-normal {
  font-size: 2rem;
  font-weight: bold;
  font-family: 'AW-Titulo';
  text-align: left;
}